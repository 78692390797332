// import "../scss/style.scss"
require('intersection-observer')
import NcElevator from './components/nc_elevator.js'
import NcScrollAnim from './components/nc_scroll-anim.js'
// import NcMap from './components/nc_map.js'
Vue.createApp({})
.component('NcElevator', NcElevator)
.component('NcScrollAnim', NcScrollAnim)
// .component('NcMap', NcMap)
.mount('#app')
