export default {
    props: {
        sentinalName: {
            type: String,
            required: true,
        },
        threshold: {
            type: Number,
            default: 0
        },
        myClass: {
            type: String,
            default: 'v-elevator'
        },
        myNavClass: {
            type: String,
            default: 'v-elevator-nav'
        }
    },
    data() {
        return {
            isIntersectingElement: false,
            option: {
                root: null,
                rootMargin: '-59% 0% -40% 0%',
                threshold: [this.threshold],
            }
        }
    },
    template: `
            <teleport to="#nav">
                <button @click="scroll" :class="[myNavClass, classObject.nav]"><slot name="nav"></slot></button>
            </teleport>
            <div :ref="sentinalName" :class="[myClass, classObject.target]"><slot name="contents"></slot></div>
    `,
    computed: {
        classObject() {
            return (
                this.isIntersectingElement ? {target:'__isEntering', nav:'__isCurrent'} : {target:'__isLeaving', nav:'__isNotCurrent'}
            )
        },
    },
    methods: {
        handler : function (entries) {
            if(entries[0].isIntersecting){
                this.isIntersectingElement = true
            }else{
                this.isIntersectingElement = false
            }
        },
        scroll : function (){
            const elm = this.$refs[this.sentinalName]
            const scrollY = elm.getBoundingClientRect().top + window.pageYOffset
            window.scrollTo({
                top: scrollY,
                behavior: 'smooth'
            })
        }
    },
    mounted() {
        const sentinal = this.$refs[this.sentinalName]
        const observer = new IntersectionObserver(this.handler, this.option)
        observer.observe(sentinal)
    }
}