export default {
    props: {
        sentinalName: {
            type: String,
            required: true,
        },
        threshold: {
            type: Number,
            default: 0.2
        },
        myClass: {
            type: String,
            default: 'v-scroll-Anim __fade'
        }
    },
    data() {
        return {
            isIntersectingElement: false,
            option: {
                root: null,
                rootMargin: '0% 0% -20% 0%',
                threshold: [this.threshold],
            }
        }
    },
    template: `
    <div :ref="sentinalName" :class="[myClass, classObject]"><slot></slot></div>
    `,
    computed: {
        classObject() {
            return (
                this.isIntersectingElement ? '__isEntering' : '__isLeaving'
            )
        }
    },
    methods: {
        handler : function (entries) {
            if(entries[0].isIntersecting){
                this.isIntersectingElement = true
            }else{
                this.isIntersectingElement = false
            }
        }
    },
    mounted() {
        const sentinal = this.$refs[this.sentinalName]
        const observer = new IntersectionObserver(this.handler, this.option)
        observer.observe(sentinal)
    }
}